import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { environment } from '../../../environments/environment';

@Injectable()
export class PlexGuard implements CanActivate {
	constructor() {}

	canActivate() {
		return environment.product === 'plex';
	}
}
